import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

export const calcFingerprintProId = async (): Promise<string | null> => {
  try {
    const fp = await FingerprintJS.load({
      apiKey: '2lIvY6ULBG8Jnez9oxLY',
      region: 'ap',
    });
    const { visitorId } = await fp.get();
    return visitorId;
  } catch {
    return null;
  }
};
