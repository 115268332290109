import { css } from '@emotion/react';
import React from 'react';
import { alignCss } from '~/styles/alignCss';

export const termsAndConditions: {
  title: string;
  node: React.ReactNode;
}[] = [
  {
    title: '事業者としての実態に関して',
    node: (
      <>
        タイミーの利用に際して、
        <ul
          css={css`
            list-style: disc;
            padding-left: 1rem;
          `}
        >
          <li>
            <b>法人</b>: 登記が行われている必要があります。
          </li>
          <li>
            <b>個人事業主</b>: 開業届を税務署等に提出している必要があります。
          </li>
        </ul>
        <br />
        確認のため、弊社からご連絡を差し上げることがございます。
      </>
    ),
  },
  {
    title: '掲載をお断りしている業務に関して',
    node: (
      <>
        職業安定法第32条の11に則り、建設業務（建物の建築、内装工事、ハウスクリーニングなど）、港湾運送業務（船舶や港湾倉庫などで貨物を取り扱う仕事）の求人の掲載はお断りしております。
      </>
    ),
  },
  {
    title: 'マッチングの仕組みに関して',
    node: (
      <>
        <p css={alignCss({ mb: 1 })}>
          タイミーは掲載された求人にワーカーが「面接なし」でマッチングします。
          <br />
          求人は勤務データ（事業者からの評価や直前キャンセル率）に基づき、勤務実績の優れたワーカーから先行公開されるため、安心してご利用いただけます。
          <br />
          マッチングは、
          <a
            href="https://help.timee.co.jp/ja/knowledge/%E3%83%9E%E3%83%83%E3%83%81%E3%83%B3%E3%82%B0%E5%BE%8C%E3%81%AB%E3%82%AD%E3%83%A3%E3%83%B3%E3%82%BB%E3%83%AB%E3%81%AF%E3%81%A7%E3%81%8D%E3%82%8B%E3%81%AE"
            target="_blank"
            rel="noopener noreferrer"
          >
            特定の条件
          </a>
          を満たす場合のみキャンセル可能です。
          <br />
          また、求人概要に必要な条件を記載することで、業務に適したワーカーが集まりやすくなります。
        </p>
      </>
    ),
  },
  {
    title: 'サービス利用料',
    node: 'サービス利用料は日当と交通費の30％になります。',
  },
  {
    title: '雇用契約に関して',
    node: (
      <>
        ワーカー・事業者の間にて、直接雇用契約を結んでいただきます。
        <br />
        労働者派遣事業（タイミーが雇用するワーカーを事業者に派遣するもの）ではありません。
      </>
    ),
  },

  {
    title: '利用規約・利用契約条項',
    node: (
      <>
        「
        <a
          href="https://lp.timee.co.jp/hubfs/Timee%E4%BA%8B%E6%A5%AD%E8%80%85%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Timee事業者利用規約
        </a>
        」「
        <a
          href="https://lp.timee.co.jp/hubfs/%E5%88%A9%E7%94%A8%E5%A5%91%E7%B4%84.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Timee利用契約条項
        </a>
        」をご確認ください。
      </>
    ),
  },
  {
    title: 'プライバシーポリシー',
    node: (
      <>
        ご送信いただくメールアドレスその他の個人情報については、必要に応じた弊社担当者からのご連絡や弊社サービス又は弊社セミナーに関するご案内をお送りする等の目的で、厳正な管理の下、
        <a href="https://timee.co.jp/policy" target="_blank" rel="noopener noreferrer">
          プライバシーポリシー
        </a>
        に従って利用させていただきます。
      </>
    ),
  },
  {
    title: '必要となる営業許認可等について',
    node: (
      <>
        事業を行うにあたり適切な営業許認可等を取得していない場合はご利用いただけません。業種ごとに必要な営業許認可等の一例は下記リンクよりご確認いただけます。
        <br />
        <a
          href="https://sites.google.com/timee.co.jp/license"
          target="_blank"
          rel="noopener noreferrer"
        >
          営業許認可等一覧（※一例）
        </a>
      </>
    ),
  },
];
