import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const calcFingerprintId = async (): Promise<string | null> => {
  try {
    const fp = await FingerprintJS.load();
    const { visitorId } = await fp.get();
    return visitorId;
  } catch {
    return null;
  }
};
