import { textCss } from '~/styles/textCss';
import { css, keyframes } from '@emotion/react';
import { colors } from '~/styles/colors';

const arrowAnimation = keyframes`
  from {
    transform: translateY(0);
    opacity: 100%;
  }

  to {
    transform: translateY(20px);
    opacity: 0%;
  }
`;

export const tac = {
  outerContainer: css`
    position: relative;
  `,
  container: css`
    ${textCss({ size: 's' })}

    height: 370px;
    overflow-y: scroll;
    border: 1px solid ${colors.gray5};
    padding: 1rem;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  `,
  overlay: {
    container: css`
      ${textCss({ size: 'l', weight: 'bold' })}

      border-radius: 2px;
      position: absolute;
      background: linear-gradient(#0000, #0005);
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `,
    arrowsContainer: css`
      margin-top: -60px;
    `,
    arrowContainer: css`
      height: 20px;
      animation: ${arrowAnimation} 1s ease infinite;
    `,
    arrow: css`
      display: block;
      width: 16px;
      height: 16px;
      border-top: 6px solid ${colors.black};
      border-left: 6px solid ${colors.black};
      transform: rotate(225deg);
    `,
  },
  title: css`
    ${textCss({ weight: 'bold' })}

    background-color: ${colors.gray6};
    border-radius: 16px;
    padding: 0.5rem 1rem;
    margin-bottom: 8px;
  `,
};

export const forWorkerNote = (args: { error: boolean }) => css`
  ${textCss({ size: 's', weight: 'bold' })}

  margin-top: 4px;
  color: ${args.error ? colors.alert : colors.black};

  & > a {
    color: ${args.error ? colors.alert : colors.black};
  }
`;
