import { css } from '@emotion/react';
import { colors } from '~/styles/colors';
import { textCss } from '~/styles/textCss';

export const email = css`
  ${textCss({
    weight: 'bold',
    color: colors.accent,
  })}

  word-wrap: break-word;
`;
